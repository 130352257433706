export const MapStyles = {
    'osm-bright': 'https://elocation.oracle.com/mapviewer/pvt/res/style/osm-bright/style.json',
    'osm-dark-matter': 'https://elocation.oracle.com/mapviewer/pvt/res/style/osm-dark-matter/style.json'
};

export const IconRotation = {
    'rocket_11': 45,
    'rocket_white': 45,
    'rocket_black': 45,
    'circle_stroked_11': 0,
    'swimming_11': 0
};