import React, { useRef, useEffect, useState } from 'react';
import { PanelProps, Field, Vector } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';
import './styles.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import { MapStyles, IconRotation } from 'MapStyles';
import { DatasetDefinition, GeoJsonFeature } from 'maptypes';
import { updateDataset, removeDataset, getRGBA } from './MapHelper';
import { boundaries, goals, buoys } from './boundaries';
import whiteRocket from 'rocket-white.svg';
import blackRocket from 'rocket-black.svg';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-4.1432);
  const [lat, setLat] = useState(50.3541);
  const [zoom, setZoom] = useState(14.15);
  const [icon, setIcon] = useState('rocket_11');
  let [usedWidth, setUsedWidth] = useState(width);
  let [usedHeight, setUsedHeight] = useState(height);
  let [usedTrailSize, setUsedTrailSize] = useState(options.trailSize);
  const [moveEventSet, setMoveEventSet] = useState(false);
  const [currentMapStyle, setCurrentMapStyle] = useState(options.mapStyle);

  const BOAT_POSITION_DATASET_NAME = 'sail-boat-locations';
  const BOAT_TRAILS_DATASET_NAME = 'sail-boat-trails';
  const RACE_BOUNDARIES_DATASET_NAME = 'race-boundaries';
  const RACE_GOALS_DATASET_NAME = 'race-goals';
  const RACE_BUOYS_DATASET_NAME = 'race-buoys';

  //Boat colors based on their ID
  const COLORS = {
    'AUS': '#00F',
    'DEN': '#F00',
    'ESP': '#A50',
    'FRA': '#808',
    'GBR': '#303',
    'JPN': '#0F0',
    'NZL': '#2FC',
    'USA': '#F0F',

  };

  //Boat current position (marker)
  const BOAT_POSITIONS_DATASET: DatasetDefinition = {
    name: BOAT_POSITION_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [

      ]
    },

    renderer: {
      type: 'symbol',
      iconId: options.iconStyle,
      iconScale: 1.5,
      iconAnchor: 'center',
      textField: 'name',
      textFont: 'Noto Sans Regular',
      textIgnorePlacement: true,
      textAllowOverlap: true,
      textOffset: [0, 1],
      textAutoPlacement: false
    }
  };

  //Boats' past locations (trail)
  const BOAT_TRAILS_DATASET: DatasetDefinition = {
    name: BOAT_TRAILS_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [

      ]
    },

    // how to display the trailsData in their normal state
    renderer: {
      type: 'line',
      zoomToData: false,  // zoom and pan the map to the extent of the dataset
      lineColor: ['get', 'color'],
      lineWidth: 3,
      lineOpacity: 0.5,
      //lineOpacity: 'use-gradient-opacity',
      popup: ['id', 'name']
    },
  };

  //Race boundaries (delimiter lines)
  const RACE_BOUNDARIES_DATASET: DatasetDefinition = {
    name: RACE_BOUNDARIES_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: boundaries.features
    },

    renderer: {
      type: 'line',
      zoomToData: false,  // zoom and pan the map to the extent of the dataset
      lineColor: ['get', 'stroke'],
      lineWidth: ['get', 'draw'],
      lineOpacity: 1,
      popup: ['id', 'title']
    },
  };

  //Race goals between buoys (lines)
  const RACE_GOALS_DATASET: DatasetDefinition = {
    name: RACE_GOALS_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: goals.features
    },

    renderer: {
      type: 'line',
      zoomToData: false,  // zoom and pan the map to the extent of the dataset
      lineColor: '#5f5',
      lineWidth: 2,
      lineOpacity: 1,
      popup: ['id', 'title', 'type']
    },
  };

  //Race buoys (points)
  const RACE_BUOYS_DATASET: DatasetDefinition = {
    name: RACE_BUOYS_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: buoys.features
    },

    renderer: {
      type: 'circle',
      zoomToData: false,  // zoom and pan the map to the extent of the dataset
      popup: ['id', 'title', 'type'],
      circleRadius: 500,
      circleColor: '#3a3',
      circleOpacity: 0.4
    },
  };

  //Initialize once
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: MapStyles[currentMapStyle],
      center: [lng, lat],
      zoom: zoom,
      preserveDrawingBuffer: false,
      transformRequest: (url, resourceType) => {
        // Supply the required header when requesting vector tiles from the eLocation server.

        if (resourceType === 'Tile' && (
          url.startsWith('https://elocation.oracle.com/mapviewer/pvt') ||
          url.startsWith('https://elocation-stage.oracle.com/mapviewer/pvt') ||
          url.startsWith('https://maps.oracle.com/mapviewer/pvt')
        )) {
          return {
            url: url,
            headers: { 'x-oracle-pvtile': 'OracleSpatial' },
            credentials: 'include'
          }
        }
        else {
          return {
            url: url
          }
        }
      }
    });
  });

  //Add move event
  useEffect(() => {
    if (!map.current || moveEventSet) {
      return; // wait for map to initialize
    }
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    console.log('move event set!');
    setMoveEventSet(true);

    //Add rocket icons
    let img1 = new Image(19, 19);
    img1.onload = () => map.current.addImage('rocket_black', img1);
    img1.src = blackRocket;

    let img2 = new Image(19, 19);
    img2.onload = () => map.current.addImage('rocket_white', img2);
    img2.src = whiteRocket;
    // document.getElementById('oracle-maps-container')?.addEventListener('resize', function () {
    //   map.current.resize();
    // });
  });

  //Read Grafana's panel dimensions and resize MapLibre canvas accordingly
  useEffect(() => {
    if (!map.current) {
      return; //wait for map to initialize
    }
    if (usedWidth !== width || usedHeight !== height) {
      map.current.resize();
      setUsedWidth(width);
      setUsedHeight(height);
    }
  });

  //Modify trail size on Panel settings change
  useEffect(() => {
    if (!map.current) {
      return; //wait for map to initialize
    }
    if (usedTrailSize !== options.trailSize) {
      setUsedTrailSize(options.trailSize);
    }
  });

  //Change map style on Panel settings change
  useEffect(() => {
    if (!map.current) {
      return; //wait for map to initialize
    }
    if (currentMapStyle !== options.mapStyle) {
      map.current.setStyle(MapStyles[options.mapStyle]);
      setCurrentMapStyle(options.mapStyle);
    }
  });

  const MULTIPLIER = 0.0000001;//lon/lat values need to be divided by 10000000 (multiplied by 0.0000001).

  //Add longitude value to the boat ID at the specified time
  const addLongitude = (boats: object, id: string, t: number, v: number) => {
    let longitudeValue = v * MULTIPLIER;
    if (boats[id]) {
      const locations = boats[id];
      const index = locations.findIndex(loc => loc.t === t);
      if (index != -1) {
        locations[index].lon = longitudeValue;
      }
      else {
        locations.push(
          {
            t: t,
            lon: longitudeValue
          });
      }
    }
    else {
      boats[id] = [
        {
          t: t,
          lon: longitudeValue
        }
      ]
    }
  };

  //Add latitude value to the boat ID at the specified time
  const addLatitude = (boats: object, id: string, t: number, v: number) => {
    let latitudeValue = v * MULTIPLIER;
    if (boats[id]) {
      const locations = boats[id];
      const index = locations.findIndex(loc => loc.t === t);
      if (index != -1) {
        locations[index].lat = latitudeValue;
      }
      else {
        locations.push(
          {
            t: t,
            lat: latitudeValue
          });
      }
    }
    else {
      boats[id] = [
        {
          t: t,
          lat: latitudeValue
        }
      ]
    }
  };

  //Get coordinates sorted by time
  const getCoordinates = (positions: Object[]): number[][] => {
    const sortedPositions = positions.sort((a, b) => {
      return a.t - b.t;
    });
    const coordinates: number[][] = [];
    sortedPositions.forEach(position => {
      if (position.lon && position.lat) {
        coordinates.push([position.lon, position.lat]);
      }
    });
    return coordinates;
  };

  //Calculate the boat rotation angle using the last two locations
  const getCurrentRotation = (lastPoint: number[], beforeLastPoint: number[]) : number => {
    const deltaX = lastPoint[0] - beforeLastPoint[0];
    const deltaY = lastPoint[1] - beforeLastPoint[1];
    let angle = Math.atan2(deltaY, deltaX);

    if (angle < 0) {
      angle = Math.abs(angle);
    }
    else {
      angle = 2 * Math.PI - angle;
    }

    return angle * 180 / Math.PI;
  };

  //Get a color for the given boat ID or use the default
  const getColorForId = (id: string): string =>  {
    return COLORS[id] || '#027';
  }

  //Update all map datasets using the latest data
  const updatePoints = (
    boats: object): void => {
    if (boats) {
      let trailsDatasetDefinition = { ...BOAT_TRAILS_DATASET };
      let boatsDatasetDefinition = { ...BOAT_POSITIONS_DATASET };
      let raceBoundariesDatasetDefinition = { ...RACE_BOUNDARIES_DATASET };
      let raceGoalsDatasetDefinition = { ...RACE_GOALS_DATASET };
      //let raceBuoysDatasetDefinition = { ...RACE_BUOYS_DATASET };

      let trailsDataset = trailsDatasetDefinition.data;
      let boatsPosition = boatsDatasetDefinition.data;

      for (const [id, positions] of Object.entries(boats)) {
        let color: string = getColorForId(id);
        let coordinates: number[][] = getCoordinates(positions);
        if (coordinates.length > usedTrailSize) {
          coordinates = coordinates.slice(coordinates.length - usedTrailSize);
        }
        let lastPoint: number[] = coordinates[coordinates.length - 1];
        let beforeLastPoint: number[] = [0, 0];
        if (coordinates.length > 1) {
          beforeLastPoint = coordinates[coordinates.length - 2];
        }
        let rotation: number = IconRotation[options.iconStyle] + getCurrentRotation(lastPoint, beforeLastPoint);
        //console.log(id, coordinates);
        let trailFeature: GeoJsonFeature = {
          type: 'Feature',
          properties: {
            id: id,
            name: id,
            color: color
          },
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        };
        trailsDataset.features.push(trailFeature);

        let pointFeature: GeoJsonFeature = {
          type: 'Feature',
          properties: {
            id: id,
            name: id,
            color: color,
            rotation: rotation
          },
          geometry: {
            type: 'Point',
            coordinates: [lastPoint[0], lastPoint[1]]
          }
        };
        boatsPosition.features.push(pointFeature);
      }

      if (icon !== options.iconStyle) {
        setIcon(options.iconStyle);
        removeDataset(map.current, boatsDatasetDefinition);
      }

      updateDataset(map.current, trailsDatasetDefinition);
      updateDataset(map.current, boatsDatasetDefinition);
      updateDataset(map.current, raceBoundariesDatasetDefinition);
      updateDataset(map.current, raceGoalsDatasetDefinition);
      //updateDataset(map.current, raceBuoysDatasetDefinition);//TODO: add buoys circle geometries
    }
  };

  //If the map is loaded, read the data series and get the relevant location information
  //Datasource has 4 columns: t: time, b: identifier, m: metric, v: value
  if (map.current && map.current.loaded()) {
    //Add data
    const timestamps = data.series
      .map(series => series.fields.find(field => field.name === 't'));
    const ids = data.series
      .map(series => series.fields.find(field => field.name === 'b'));
    const metrics = data.series
      .map(series => series.fields.find(field => field.name === 'm'));
    const values = data.series
      .map(series => series.fields.find(field => field.name === 'v'));
    
    //Find longitude and latitude values
    const boats = {

    };

    //Get the logitude indexes among all the metrics in the datasource
    const longitudeIndexes: number[] = metrics[0]?.values.toArray().reduce((array, metric, index) => {
      if (metric === 'LONGITUDE_GPS_unk') {//We know the longitude metric is called 'LONGITUDE_GPS_unk'
        array.push(index);
      };
      return array;
    }, []);
    
    //Get the latitude indexes among all the metrics in the datasource
    const latitudeIndexes: number[] = metrics[0]?.values.toArray().reduce((array, metric, index) => {
      if (metric === 'LATITUDE_GPS_unk') {//We know the latitude metric is called 'LATITUDE_GPS_unk'
        array.push(index);
      };
      return array;
    }, []);

    //Add the longitude values to the boats object
    longitudeIndexes.forEach(index => {
      addLongitude(boats, ids[0]?.values.get(index), timestamps[0]?.values.get(index), values[0]?.values.get(index));
    });

    //Add the latitude values to the boats object
    latitudeIndexes.forEach(index => {
      addLatitude(boats, ids[0]?.values.get(index), timestamps[0]?.values.get(index), values[0]?.values.get(index));
    });
    
    //Update all the map datasets with the current boat information
    updatePoints(boats);
  };

  const styles = getStyles();

  let infoBar = <div></div>;

  if (options.showInfoBar) {
    infoBar = (
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
    );
  }

  return (
    <div>
      {infoBar}
      <div id="oracle-maps-container" ref={mapContainer} className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )} />
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
});
