import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
    .addSelect({
      path: 'mapStyle',
      name: 'Map Style',
      description: 'Background map style',
      defaultValue: 'osm-bright',
      settings: {
        options: [
          {
            value: 'osm-bright',
            label: 'OSM Bright'
          },
          {
            value: 'osm-dark-matter',
            label: 'OSM Dark Matter'
          }
        ]
      }
    }).addBooleanSwitch({
      path: 'showInfoBar',
      name: 'Show Info Bar',
      defaultValue: true,
    }).addRadio({
      path: 'iconStyle',
      name: 'Icon style',
      defaultValue: 'rocket_11',
      settings: {
        options: [
          {
            value: 'rocket_11',
            label: 'Rocket'
          },
          // {
          //   value: 'rocket_white',
          //   label: 'White Rocket'
          // },
          {
            value: 'circle_stroked_11',
            label: 'Circle'
          },
          {
            value: 'swimming_11',
            label: 'Swimmer'
          }
        ]
      }
    }).addRadio({
      path: 'trailSize',
      name: 'Trail Size',
      defaultValue: 50,
      settings: {
        options: [
          {
            value: 20,
            label: '20'
          },
          {
            value: 50,
            label: '50'
          },
          {
            value: 100,
            label: '100'
          }
        ]
      }
    });
    // .addTextInput({
    //   path: 'text',
    //   name: 'Simple text option',
    //   description: 'Description of panel option',
    //   defaultValue: 'Default value of text input option',
    // })
    // .addBooleanSwitch({
    //   path: 'showSeriesCount',
    //   name: 'Show series counter',
    //   defaultValue: false,
    // })
    // .addRadio({
    //   path: 'seriesCountSize',
    //   defaultValue: 'sm',
    //   name: 'Series counter size',
    //   settings: {
    //     options: [
    //       {
    //         value: 'sm',
    //         label: 'Small',
    //       },
    //       {
    //         value: 'md',
    //         label: 'Medium',
    //       },
    //       {
    //         value: 'lg',
    //         label: 'Large',
    //       },
    //     ],
    //   },
    //   showIf: config => config.showSeriesCount,
    // })
    // .addRadio({
    //   path: 'color',
    //   name: 'Circle color',
    //   defaultValue: 'red',
    //   settings: {
    //     options: [
    //       {
    //         value: 'red',
    //         label: 'Red'
    //       },
    //       {
    //         value: 'green',
    //         label: 'Green'
    //       },
    //       {
    //         value: 'blue',
    //         label: 'Blue'
    //       }
    //     ]
    //   }
    // });
});
