
export const boundaries = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "title": "Boundary",
        "stroke": "#0000FF",
        "draw": 1,
        "filled": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.142678,
            50.35842
          ],
          [
            -4.13595,
            50.356953
          ],
          [
            -4.133345,
            50.355303
          ],
          [
            -4.133992,
            50.352838
          ],
          [
            -4.135735,
            50.345616
          ],
          [
            -4.15123,
            50.345335
          ],
          [
            -4.153278,
            50.350179
          ],
          [
            -4.147394,
            50.352729
          ],
          [
            -4.142678,
            50.35842
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "EZ",
        "stroke": "#0080FF",
        "draw": 1,
        "filled": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.153889,
            50.35011
          ],
          [
            -4.147808,
            50.352815
          ],
          [
            -4.148436,
            50.362174
          ],
          [
            -4.139723,
            50.363148
          ],
          [
            -4.135528,
            50.356844
          ],
          [
            -4.133202,
            50.355497
          ],
          [
            -4.133202,
            50.345346
          ],
          [
            -4.151616,
            50.345106
          ],
          [
            -4.153889,
            50.35011
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "VIP+OSB",
        "stroke": "#9A00C0",
        "draw": 1,
        "filled": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.133471,
            50.352804
          ],
          [
            -4.13366,
            50.35293
          ],
          [
            -4.134217,
            50.351147
          ],
          [
            -4.134028,
            50.351153
          ],
          [
            -4.133471,
            50.352804
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "BYOB",
        "stroke": "#FF0012",
        "draw": 1,
        "filled": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.13313,
            50.352729
          ],
          [
            -4.132743,
            50.352723
          ],
          [
            -4.132797,
            50.355194
          ],
          [
            -4.13313,
            50.355274
          ],
          [
            -4.13313,
            50.352729
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Access",
        "stroke": "#FFFF00",
        "draw": 1,
        "filled": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.13692,
            50.359423
          ],
          [
            -4.135519,
            50.35685
          ],
          [
            -4.133175,
            50.35548
          ],
          [
            -4.133148,
            50.345438
          ],
          [
            -4.132546,
            50.34545
          ],
          [
            -4.132573,
            50.355452
          ],
          [
            -4.135277,
            50.356953
          ],
          [
            -4.136597,
            50.359544
          ],
          [
            -4.13692,
            50.359423
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Access",
        "stroke": "#FFFF00",
        "draw": 1,
        "filled": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.148814,
            50.360443
          ],
          [
            -4.148391,
            50.360466
          ],
          [
            -4.147844,
            50.352844
          ],
          [
            -4.148158,
            50.352735
          ],
          [
            -4.148814,
            50.360443
          ]
        ]
      }
    }
  ]
};

export const goals = {
  "type": "FeatureCollection",
  "properties": {
    "RaceID": 21071701,
    "RaceType": "Fleet",
    "CreationTimeDate": "2021-07-17T14:09:58+01:00",
    "RaceStartTime": "2021-07-17T14:14:00+01:00"
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "title": "SL1",
        "type": "Mark",
        "id": 1
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.147332,
            50.348912
          ],
          [
            -4.146281,
            50.350706
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 3
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.137495,
            50.356019
          ],
          [
            -4.13542,
            50.35493
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "WG1",
        "type": "Mark",
        "id": 4
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.144996,
            50.347817
          ],
          [
            -4.143011,
            50.347066
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 5
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.137495,
            50.356019
          ],
          [
            -4.13542,
            50.35493
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "WG1",
        "type": "Mark",
        "id": 6
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.144996,
            50.347817
          ],
          [
            -4.143011,
            50.347066
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 7
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.137495,
            50.356019
          ],
          [
            -4.13542,
            50.35493
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "FL",
        "type": "Mark",
        "id": 8
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -4.145086,
            50.360833
          ],
          [
            -4.145652,
            50.360145
          ]
        ]
      }
    }
  ]
};

export const buoys = {
  "type": "FeatureCollection",
  "properties": {
    "RaceID": 21071701,
    "RaceType": "Fleet",
    "CreationTimeDate": "2021-07-17T14:09:58+01:00",
    "RaceStartTime": "2021-07-17T14:14:00+01:00"
  },
  "features": [
    {
      "type": "Feature",
      "properties": {
        "title": "SL1",
        "type": "Mark",
        "id": 1
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.147332,
            50.348912
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "SL1",
        "type": "Mark",
        "id": 1.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.146281,
            50.350706
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "M1",
        "type": "Mark",
        "id": 2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.139543,
            50.348579
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 3
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.137495,
            50.356019
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 3.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.13542,
            50.35493
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "WG1",
        "type": "Mark",
        "id": 4
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.144996,
            50.347817
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "WG1",
        "type": "Mark",
        "id": 4.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.143011,
            50.347066
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 5
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.137495,
            50.356019
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 5.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.13542,
            50.35493
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "WG1",
        "type": "Mark",
        "id": 6
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.144996,
            50.347817
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "WG1",
        "type": "Mark",
        "id": 6.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.143011,
            50.347066
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 7
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.137495,
            50.356019
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "LG1",
        "type": "Mark",
        "id": 7.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.13542,
            50.35493
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "FL",
        "type": "Mark",
        "id": 8
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.145086,
            50.360833
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "FL",
        "type": "Mark",
        "id": 8.2
      },
      "geometry": {
        "type": "Point",
        "coordinates": [
            -4.145652,
            50.360145
        ]
      }
    }
  ]
};